import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { RoiCards } from '../components/roi-calculator/cards/RoiCards';
import { initialSliderValues, roiBottomCtas, roiSliderData } from '../components/roi-calculator/data';
import { RoiFooter } from '../components/roi-calculator/footer/RoiFooter';
import { PlanSelect } from '../components/roi-calculator/select/PlanSelect';
import { Slider } from '../components/roi-calculator/slider/Slider';
import { Plan, calculateRoiValues } from '../components/roi-calculator/utils';
import { borderRadius, flex } from '../design/animations/display';
import { brand1 } from '../design/colors/brand';
import { ButtonLink } from '../design/components/button/Button';
import { Divider } from '../design/components/divider/Divider';
import { Panel } from '../design/containers/panel/Panel';
import TwoCtaPlacementContainer from '../design/containers/placements/TwoCtaPlacementContainer';
import { breakpointSmall } from '../design/responsive/breakpoints';
import Spacing, { spacings } from '../design/spacing/Spacing';
import { BodyP, Heading1 } from '../design/typography/Typography';
import PageContainer from '../pageContainers/PageContainer';

const CenteredContent = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  ${borderRadius({ all: '12px' })};
  padding-top: 60px;
`;

const TwoColGrid = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  ${breakpointSmall(css`
    grid-template-columns: 5fr 7fr;
  `)}
`;

const PanelContainer = styled(Panel).attrs({
  padding: { breakpointMedium: '60px', base: '32px' },
  borderRadius: '0px',
})``;
const BackgroundContainer = styled(Panel).attrs({ background: 'brand3WeelLogoAzulRight', borderRadius: '0px' })``;

const Flex = styled.section`
  ${flex({ direction: 'column', gap: '32px' })}
`;

const TotalExpensesContainer = styled.div`
  ${flex({ gap: '24px', align: 'center' })}
`;

const ExpenseTotalOutput = styled.div`
  background-color: ${brand1};
  text-align: center;
  padding: ${spacings['12px']};
  ${borderRadius({ all: '8px' })}
`;

const ButtonContainer = styled(TwoCtaPlacementContainer)`
  ${flex({ justify: 'center' })}
`;

const CallsToAction = () => (
  <Spacing top="60px">
    <ButtonContainer
      cta1Placement={
        <ButtonLink styleType="primary" link={roiBottomCtas.primary.link}>
          {roiBottomCtas.primary.children}
        </ButtonLink>
      }
      cta2Placement={
        <ButtonLink styleType="reverseSecondary" link={roiBottomCtas.secondary.link}>
          {roiBottomCtas.secondary.children}
        </ButtonLink>
      }
    />
  </Spacing>
);

const TotalExpensesCounter = ({ total }: { total: number }) => (
  <TotalExpensesContainer>
    <BodyP type="heading4">Total expenses per month: </BodyP>
    <ExpenseTotalOutput>
      <BodyP type="heading4">{total}</BodyP>
    </ExpenseTotalOutput>
  </TotalExpensesContainer>
);

const PlanInput = ({ onChange, activePlan }: { onChange: (plan: Plan) => void; activePlan: Plan }) => (
  <PanelContainer background="brand1SolidWeelLogoReverse">
    <Spacing bottom="32px">
      <Heading1 textAlign="left">Weel savings and ROI calculator</Heading1>
    </Spacing>
    <PlanSelect onChange={onChange} activePlan={activePlan} />
  </PanelContainer>
);

const Sliders = ({
  onChange,
  total,
}: {
  total: number;
  onChange: ({ name, value }: { name: string; value: number }) => void;
}) => (
  <PanelContainer background="whiteSolid">
    <Flex>
      {roiSliderData.map((data) => (
        <Slider key={data.name} {...data} onChange={(value) => onChange({ name: data.name, value })} />
      ))}
    </Flex>
    <Spacing bottom="24px" top="24px">
      <Divider styleType="grey300" />
    </Spacing>
    <TotalExpensesCounter total={total} />
  </PanelContainer>
);

/**
 * RoiCalculator page.
 */
const RoiCalculator = () => {
  const [activePlan, setActivePlan] = useState<Plan>('premium');
  const [sliderValues, setSliderValues] = useState(initialSliderValues);

  const onSliderChange = ({ name, value }: { name: string; value: number }) =>
    setSliderValues((prev) => ({ ...prev, [name]: value }));

  const calculatorOutput = calculateRoiValues({ ...sliderValues, plan: activePlan });
  const totalExpenses = sliderValues.employees * sliderValues.expenses;

  return (
    <BackgroundContainer>
      <PageContainer>
        <CenteredContent data-testid="roi-calculator">
          <TwoColGrid>
            <PlanInput onChange={setActivePlan} activePlan={activePlan} />
            <Sliders onChange={onSliderChange} total={totalExpenses} />
          </TwoColGrid>
          <PanelContainer background="brand5Solid">
            <RoiCards {...calculatorOutput} activePlan={activePlan} />
            <RoiFooter />
          </PanelContainer>
        </CenteredContent>
        <CallsToAction />
      </PageContainer>
    </BackgroundContainer>
  );
};

export default RoiCalculator;
