import { PrismicLinkType } from '../link/data/Link.generateData';
import { Plan } from './utils';

export const roiSliderData = [
  {
    options: ['1', '25', '50', '75', '100', '125+'],
    label: 'Employees with expense requirements',
    name: 'employees',
    min: 1,
    max: 125,
  },
  {
    options: ['1', '10', '20', '30', '40', '50+'],
    label: 'Number of expenses per month per employee',
    name: 'expenses',
    min: 1,
    max: 50,
  },
  {
    options: ['50k', '100k', '150k', '200k', '250k', '300+'],
    label: 'Average employee salary',
    name: 'employeeSalary',
    min: 50000,
    max: 300000,
  },
  {
    options: ['50k', '100k', '150k', '200k', '250k', '300+'],
    label: 'Average finance team salary',
    name: 'finEmployeeSalary',
    min: 50000,
    max: 300000,
  },
];

export const initialSliderValues = {
  employees: 25,
  expenses: 10,
  employeeSalary: 100000,
  finEmployeeSalary: 100000,
};

export const roiDisclaimer = {
  title: 'Key assumptions',
  points: [
    'Team members spend an average of 10 minutes prepare and process each expense. External research puts this at up to 20 minutes *',
    'Finance team members on average spend a further 5 minutes to review each expense. External research puts this at up to 18 minutes for each correction that needs to be made *',
    'Time spent by employees and finance team reduced by approximately 90% with an automated expense management solution such as Weel.',
    'Weel subscription cost based on the lowest plan that meets the customers needs, including base fee, plus user and transaction overages. **',
    'Return on Investment = Savings / Cost of Weel subscription ***',
  ],
};

export const roiBottomCtas = {
  primary: {
    link: { link_type: 'Document', url: '/contact-sales' } as PrismicLinkType,
    children: 'Talk to us',
  },
  secondary: {
    link: { link_type: 'Document', url: '/product-tour' } as PrismicLinkType,
    children: 'Take the tour',
  },
};

/**
 * Copy for the ROI calculator cards.
 */
export const roiCardCopy = (activePLan?: Plan) => ({
  legacy: {
    title: 'Traditional spend management',
    description: 'Total cost of managing company spending across multiple products and tools',
    metric1: 'spent per month by employees',
    metric2: 'spent per month by finance team',
    total: 'Total costs (salaries)',
  },
  weel: {
    title: 'Spend management with Weel',
    description: 'Total cost of managing company spending using one, streamlined solution',
    metric1: `Weel subscription fee (${activePLan?.charAt(0).toUpperCase()}${activePLan?.slice(1)} Plan)`,
    metric2: 'Salary costs',
    total: 'Total costs (subscription + salaries)',
  },
  savings: {
    title: 'How much you’ll save',
    description: 'Your calculated savings total when comparing Weel with traditional spend management products',
    metric1: 'Savings',
    metric2: 'Return on investment',
  },
});
