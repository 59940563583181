export type Plan = 'basic' | 'premium' | 'enterprise';
type SliderValues = {
  employees: number;
  expenses: number;
  employeeSalary: number;
  finEmployeeSalary: number;
  plan: Plan;
};

/**
 *  Calculate the output for the ROI calculator.
 */
export const calculateRoiValues = ({ employeeSalary, employees, finEmployeeSalary, expenses, plan }: SliderValues) => {
  const roundUp = (num: number) => Math.ceil(num);
  const formatPrice = (price: number) => `$${roundUp(price).toLocaleString()}`;
  const getSalaryCost = (salary: number, hours: number) => (salary / (38 * 48)) * hours;

  const hoursPmEmployee = roundUp((employees * expenses * 10) / 60);
  const hoursPmFinTeam = roundUp((employees * expenses * 5) / 60);
  const employeeSalaryCost = getSalaryCost(employeeSalary, hoursPmEmployee);
  const finEmployeeSalaryCost = getSalaryCost(finEmployeeSalary, hoursPmFinTeam);
  const totalSalaryCost = roundUp(employeeSalaryCost + finEmployeeSalaryCost);

  const planLimits = {
    basic: { employees: 5, expenses: 50 },
    premium: { employees: 10, expenses: 150 },
    enterprise: { employees: 20, expenses: 0 },
  };

  const getExtras = (plan: Plan) => {
    const extraPeople = employees - planLimits[plan].employees;
    const extraPayments = expenses - planLimits[plan].expenses;

    return { employees: extraPeople > 0 ? extraPeople : 0, expenses: extraPayments > 0 ? extraPayments : 0 };
  };

  const fees = {
    basic: 119 + getExtras('basic').employees * 5 + getExtras('basic').expenses * 0.75,
    premium: 340 + getExtras('premium').employees * 8 + getExtras('premium').expenses * 0.5,
    enterprise: 2025 + getExtras('enterprise').employees * 10,
  };

  const subFee = fees[plan];
  const weelSalaryCosts = totalSalaryCost * 0.1;
  const weelTotalCost = roundUp(subFee + weelSalaryCosts);
  const savingsPrice = totalSalaryCost - weelTotalCost;
  const annualSavings = savingsPrice * 12;
  const roi = ((savingsPrice / subFee) * 100).toFixed(1);

  const legacy = {
    metric1: `${hoursPmEmployee} hours`,
    metric2: `${hoursPmFinTeam} hours`,
    total: formatPrice(totalSalaryCost),
  };

  const weel = {
    metric1: formatPrice(subFee),
    metric2: formatPrice(weelSalaryCosts),
    total: formatPrice(weelTotalCost),
  };

  const savings = {
    metric1: formatPrice(annualSavings),
    metric2: `${roi}%`,
  };

  return { legacy, weel, savings };
};
