import React, { ChangeEvent, FC, useState } from 'react';
import styled, { css } from 'styled-components';
import { flex } from '../../../design/animations/display';
import { grey100, grey400 } from '../../../design/colors/greyscale';
import { breakpointXSmall } from '../../../design/responsive/breakpoints';
import Spacing, { spacings } from '../../../design/spacing/Spacing';
import { getTypographyStyles } from '../../../design/typography/Typography';
import { fontFamilyStyles } from '../../../design/typography/base/Text';
import { initialSliderValues } from '../data';
import { Label, RangeSlider } from './components/RangeSlider';

const TextInput = styled.input.attrs({
  type: 'text',
  'data-testid': 'slider-text-input',
  pattern: '([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]',
})`
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  width: 100%;
  max-width: 100%;
  padding: ${spacings['16px']};
  height: ${spacings['60px']};
  border: ${grey400} 1px solid;
  border-radius: ${spacings['4px']};
  background-color: ${grey100};
  ${fontFamilyStyles};
  ${getTypographyStyles('bodyLMedium')}
  ${breakpointXSmall(
    css`
      max-width: 146px;
    `
  )};
`;

const Wrapper = styled.div`
  ${flex({ direction: 'column' })};
  ${breakpointXSmall(
    css`
      ${flex({ gap: '24px', align: 'center', direction: 'row' })}
      width: 100%;
    `
  )};
`;

const ShowOnMobile = styled.div`
  ${breakpointXSmall(
    css`
      display: none;
    `
  )};
`;

export type SliderProps = {
  options: string[];
  label: string;
  name: string;
  min: number;
  max: number;
  onChange: (value: number) => void;
};

/**
 * Render Slider and Text input.
 */
export const Slider: FC<SliderProps> = ({ onChange, ...props }) => {
  const startingValue = initialSliderValues[props.name as keyof typeof initialSliderValues] || 0;
  const [sliderValue, setSliderValue] = useState(startingValue);
  const [textValue, setTextValue] = useState(startingValue.toLocaleString());

  const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const stringValue = event.target.value;
    const numValue = parseInt(stringValue.replace(',', ''));
    const newValue = stringValue ? numValue : startingValue;

    setTextValue(numValue ? numValue.toLocaleString() : '');
    setSliderValue(newValue);
    onChange(newValue);
  };

  const onSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);

    setTextValue(value.toLocaleString());
    setSliderValue(value);
    onChange(value);
  };

  const onBlur = () => !textValue && setTextValue(startingValue.toLocaleString());

  return (
    <Wrapper data-testid={`slider-container-${props.name}`}>
      <RangeSlider {...props} value={sliderValue} onChange={onSliderChange} />
      <Spacing bottom="16px">
        <ShowOnMobile>
          <Label htmlFor={props.name}>{props.label}</Label>
        </ShowOnMobile>
      </Spacing>
      <TextInput value={textValue} onChange={onTextChange} onBlur={onBlur} />
    </Wrapper>
  );
};
