import React from 'react';
import styled, { css } from 'styled-components';
import { flex, grid } from '../../../design/animations/display';
import { brand1, brand2Mid2 } from '../../../design/colors/brand';
import { grey600 } from '../../../design/colors/greyscale';
import { Divider } from '../../../design/components/divider/Divider';
import { Panel } from '../../../design/containers/panel/Panel';
import { breakpointSmall } from '../../../design/responsive/breakpoints';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP } from '../../../design/typography/Typography';
import { roiCardCopy } from '../data';
import { Plan } from '../utils';

const CardPanel = styled(Panel)`
  ${flex({ direction: 'column' })}
  ${BodyP} {
    text-align: left;
  }
`;

const ThreeColGrid = styled.section`
  ${grid({ columns: 1, gap: '16px' })}
  ${breakpointSmall(css`
    ${grid({ columns: 3, gap: '16px' })}
  `)}
`;

type BaseCardProps = {
  type: 'legacy' | 'weel';
  metric1: string;
  metric2: string;
  total: string;
};

const Title = styled(BodyP).attrs({ type: 'heading4Medium' })`
  margin-bottom: ${spacings['4px']};
`;
const Description = styled(BodyP).attrs({ type: 'bodyLRegular' })``;
const Body = styled(BodyP).attrs({ type: 'bodyMRegular' })``;
const Line = styled(Divider).attrs({ styleType: 'brand2MidOpacity0point3' })`
  margin: ${spacings['12px']} 0;
`;
const Price = styled(BodyP).attrs({ type: 'heading2' })`
  font-weight: 700;
  margin-bottom: ${spacings['4px']};
`;
const SavingsFigure = styled(Price)`
  && {
    color: ${brand1};
  }
`;
const Metric = styled(BodyP).attrs({ type: 'heading4Medium' })`
  margin-bottom: ${spacings['4px']};
`;
const CardHeaderContainer = styled.div`
  flex: 1;
`;
const FlexRow = styled.div`
  ${flex({ align: 'baseline' })}
`;
const CardHeader = ({ title, description }: { title: string; description: string }) => (
  <CardHeaderContainer>
    <Title>{title}</Title>
    <Description>{description}</Description>
  </CardHeaderContainer>
);

const BaseCard = ({ type, metric1, metric2, total, activePlan }: BaseCardProps & { activePlan?: Plan }) => {
  const copy = roiCardCopy(activePlan)[type];
  return (
    <CardPanel
      data-testid={`${type}-card`}
      background={type === 'legacy' ? 'whiteSolid' : 'brand2Solid'}
      padding="32px"
      shadow="cardShadowsSubtle"
    >
      <CardHeader title={copy.title} description={copy.description} />
      <Line />
      <Metric data-testid={`${type}-metric-1`}>{metric1}</Metric>
      <Body>{copy.metric1}</Body>
      <Line />
      <Metric data-testid={`${type}-metric-2`}>{metric2}</Metric>
      <Body>{copy.metric2}</Body>
      <Line />
      <FlexRow>
        <Price data-testid={`${type}-total`}>{total}</Price>
        <BodyP color={type === 'legacy' ? grey600 : brand2Mid2}>/ month</BodyP>
      </FlexRow>
      <BodyP type="bodyLMedium">{copy.total}</BodyP>
    </CardPanel>
  );
};

const SavingsCard = ({ metric1, metric2 }: { metric1: string; metric2: string }) => {
  const copy = roiCardCopy().savings;
  const metricNum1 = parseInt(metric1.replace('$', ''), 10);
  const metricNum2 = parseInt(metric2.replace('%', ''), 10);

  const isNegativeOrZero = (value: number) => value <= 0;

  return (
    <CardPanel background="brand3Solid" padding="32px" data-testid="savings-card">
      <Title>{copy.title}</Title>
      <Description>{copy.description}</Description>
      <Line />
      <FlexRow>
        <SavingsFigure data-testid="savings-metric-1">{isNegativeOrZero(metricNum1) ? '$0' : metric1}</SavingsFigure>
        <BodyP data-testid="savings-metric-1-cadence" color={brand2Mid2}>
          / year
        </BodyP>
      </FlexRow>
      <BodyP type="bodyLMedium">{copy.metric1}</BodyP>
      <Line />
      <SavingsFigure data-testid="savings-metric-2">{isNegativeOrZero(metricNum2) ? '0%' : metric2}</SavingsFigure>
      <BodyP type="bodyLMedium">{copy.metric2}</BodyP>
    </CardPanel>
  );
};
type CardProps = Omit<BaseCardProps, 'type'>;
type RoiCardsProps = { legacy: CardProps; weel: CardProps; savings: Omit<CardProps, 'total'>; activePlan: Plan };
/**
 * Render RoiCards component.
 */
export const RoiCards = ({ legacy, weel, savings, activePlan }: RoiCardsProps) => {
  return (
    <ThreeColGrid>
      <BaseCard type="legacy" {...legacy} />
      <BaseCard type="weel" {...weel} activePlan={activePlan} />
      <SavingsCard {...savings} />
    </ThreeColGrid>
  );
};
