import React from 'react';
import styled from 'styled-components';
import { Divider } from '../../../design/components/divider/Divider';
import Spacing, { spacings } from '../../../design/spacing/Spacing';
import { BodyP, Heading3, getTypographyStyles } from '../../../design/typography/Typography';
import { fontFamilyStyles } from '../../../design/typography/base/Text';
import { Hyperlink } from '../../richText/components';
import { roiDisclaimer } from '../data';

const Citation = styled(BodyP)<{ content: string }>`
  position: relative;
  margin-left: ${spacings['24px']};
  &:before {
    content: '${({ content }) => content}';
    left: -${spacings['32px']};
    top: 0;
    position: absolute;
    text-align: right;
    width: 24px;
  }
`;

const Citations = () => (
  <>
    <Citation content="*">
      Refer to article{' '}
      <Hyperlink
        link={{ url: 'https://www.gbta.org/blog/how-much-do-expense-reports-really-cost-a-company/', link_type: 'Web' }}
      >
        How Much Do Expense Reports Really Cost a Company
      </Hyperlink>
    </Citation>
    <Citation content="**">
      Refer to <Hyperlink link={{ url: '/pricing', link_type: 'Document' }}>Weel Pricing</Hyperlink>
    </Citation>
    <Citation content="***">
      Refer to article{' '}
      <Hyperlink
        link={{ url: 'https://online.hbs.edu/blog/post/how-to-calculate-roi-for-a-project', link_type: 'Web' }}
      >
        How to Calculate ROI for a Project
      </Hyperlink>
    </Citation>
  </>
);

const UnorderedList = styled.ul`
  ${getTypographyStyles('bodyMRegular')}
  ${fontFamilyStyles}
  list-style: disc;
  margin-left: ${spacings['24px']};
`;
/**
 * Render RoiFooter component.
 */
export const RoiFooter = () => {
  return (
    <Spacing top="60px">
      <div data-testid="roi-footer">
        <Heading3 textAlign="left">{roiDisclaimer.title}</Heading3>
        <Spacing bottom="24px" top="24px">
          <Divider styleType="grey400" />
        </Spacing>
        <UnorderedList>
          {roiDisclaimer.points.map((point) => (
            <li key={point}>{point}</li>
          ))}
        </UnorderedList>
        <Spacing bottom="24px" top="24px">
          <Divider styleType="grey400" />
        </Spacing>
        <Citations />
      </div>
    </Spacing>
  );
};
