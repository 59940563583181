import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { flex } from '../../../../design/animations/display';
import { brand2, brand1, brand3Light } from '../../../../design/colors/brand';
import { grey700, grey800 } from '../../../../design/colors/greyscale';
import { breakpointXSmall } from '../../../../design/responsive/breakpoints';
import { spacings } from '../../../../design/spacing/Spacing';
import { getTypographyStyles } from '../../../../design/typography/Typography';
import { fontFamilyStyles } from '../../../../design/typography/base/Text';

const thumbStyles = css`
  position: absolute;
  margin-top: -6px;
  height: ${spacings['24px']};
  width: ${spacings['24px']};
  border: 6px solid ${brand2};
  background: ${brand1};
  border-radius: 100vw;
  z-index: 9;
`;

const backgroundTrackStyles = css`
  height: ${spacings['12px']};
  background: ${brand3Light};
  border-radius: 100vw;
`;

const getThumbStyles = (activeWidth: number) => css`
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    left: ${activeWidth}px;
    ${thumbStyles}
  }
  ::-moz-range-thumb {
    ${thumbStyles}
  }
`;

const getBackgroundTrackStyles = () => css`
  ::-webkit-slider-runnable-track {
    ${backgroundTrackStyles}
  }
  ::-moz-range-track {
    ${backgroundTrackStyles}
  }
`;

const activeTrackStyles = (width: number) => css`
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background: ${brand2};
    width: ${width}px;
    height: ${spacings['12px']};
    border-radius: 100vw 0 0 100vw;
    z-index: 2;
  }
`;

const SliderInput = styled.input<{ activeWidth: number }>`
  width: 100%;
  position: relative;
  cursor: grab;
  ${getBackgroundTrackStyles()};
  ${({ activeWidth }) => (activeWidth ? activeTrackStyles(activeWidth) : '')};
  ${({ activeWidth }) => (activeWidth ? getThumbStyles(activeWidth - 12) : getThumbStyles(-12))};
`;
SliderInput.defaultProps = { type: 'range', role: 'slider', list: 'options' };

const SliderContainer = styled.div`
  width: 0;
  height: 0;
  opacity: 0;
  ${breakpointXSmall(css`
    width: 100%;
    height: auto;
    opacity: 1;
    ${flex({ direction: 'column', gap: '8px' })};
  `)}
`;

export const Label = styled.label`
  ${getTypographyStyles('bodyMMedium')};
  ${fontFamilyStyles}
  position: relative;
  color: ${grey800};
`;

const BrowserTickOptions = styled.div`
  ${flex({ justify: 'space-between' })};
  ${fontFamilyStyles};
  ${getTypographyStyles('bodySRegular')};
  color: ${grey700};
  text-align: right;
`;

type RangeSliderProps = {
  options: string[];
  label: string;
  name: string;
  min: number;
  max: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: number;
};
/**
 * Range slider Inpit for ROI calculator.
 */
export const RangeSlider = ({ options, label, name, min, max, value, ...props }: RangeSliderProps) => {
  const [activeWidth, setActiveWidth] = useState(0);
  const sliderRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const sliderWidth = sliderRef?.current?.clientWidth || 0;
    const percentFilled = ((value - min) * 100) / (max - min);

    if (percentFilled > 100) setActiveWidth(sliderWidth);
    if (percentFilled < 0) setActiveWidth(0);
    if (percentFilled >= 0 && percentFilled <= 100) setActiveWidth((sliderWidth * percentFilled) / 100);
  }, [max, min, value]);

  return (
    <SliderContainer>
      <Label htmlFor={name} data-testid="slider-label">
        {label}
      </Label>
      <SliderInput
        id={name}
        name={name}
        activeWidth={activeWidth}
        ref={sliderRef}
        min={min}
        max={max}
        value={value}
        {...props}
      />
      <BrowserTickOptions>
        {options.map((option) => (
          <p key={option}>{option}</p>
        ))}
      </BrowserTickOptions>
      <datalist id="options" data-testid="slider-data-list">
        {options.map((option) => (
          <option key={option} value={option} label={option}></option>
        ))}
      </datalist>
    </SliderContainer>
  );
};
