import React, { FC, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { borderRadius } from '../../../design/animations/display';
import { brand2, brand2Light, brand4 } from '../../../design/colors/brand';
import { black, grey400, grey700, white } from '../../../design/colors/greyscale';
import { planDropDownShadow } from '../../../design/colors/shadows';
import { Button } from '../../../design/components/button/Button';
import { transparentTypeStyle } from '../../../design/components/button/styles/buttonType/brand/transparent/transparent';
import { spacings } from '../../../design/spacing/Spacing';
import { BodyP } from '../../../design/typography/Typography';
import ChevronArrow from '../../../images/react-icons/thinChevron.inline.svg';
import PostFilter from '../../../slices/contentHubCards/components/TopicFilter/TopicFilter';
import {
  FilterOptions,
  ExpandCollapseContainer,
} from '../../../slices/contentHubCards/components/TypeFilter/FiltersShared';
import { Plan } from '../utils';

const Container = styled.div`
  width: 100%;
`;

const PlanDropdown = styled(ExpandCollapseContainer)`
  ${borderRadius({ all: '8px' })};
  ${planDropDownShadow};
  top: ${spacings['4px']};
  padding: 0;
  gap: 0;
`;

const ActivePlanButton = styled(Button)`
  ${borderRadius({ all: '8px' })};
  background-color: ${white};
  border: 1px solid ${grey400};
  width: 100%;
  position: relative;
  z-index: 2;
  justify-content: space-between;
  &:hover {
    background-color: ${white};
  }
`;

export const Chevron = styled(ChevronArrow)<FilterOptions>`
  ${({ expand }) => (expand ? 'transform: rotate(180deg);' : 'transform: rotate(0deg);')}
  transition: transform 0.3s ease;
  width: ${spacings['12px']};
  height: 6px;
  * {
    stroke: ${black};
  }
`;

const hoverStyles = css`
  background: ${brand2Light};
  border-color: ${brand2Light};
  color: ${brand2};
`;

const defaultStyles = css`
  ${transparentTypeStyle};
  background: transparent;
`;

const Option = styled(Button)<{ isActive: boolean }>`
  ${({ isActive }) => (isActive ? hoverStyles : defaultStyles)};
  &:hover {
    ${hoverStyles};
    border-bottom: 1px solid ${grey400};
  }
  :last-of-type {
    border-bottom: none;
  }
  border-bottom: 1px solid ${grey400};
  border-radius: 0;
  display: block;
  height: auto;
`;

type PlanSelectProps = {
  onChange: (plan: Plan) => void;
  activePlan: Plan;
};
/**
 * Plan Filter component for ROI Calculator.
 */
export const PlanSelect: FC<PlanSelectProps> = ({ onChange, activePlan }) => {
  const plans: Plan[] = ['basic', 'premium', 'enterprise'];
  const [expanded, setExpanded] = useState<boolean>(false);

  const filterContainer = useRef<HTMLHeadingElement>(null);
  const scrollHeight = filterContainer?.current?.scrollHeight;

  const toggleMenu = () => setExpanded(!expanded);
  const formatOption = (plan: Plan) => `${plan.charAt(0).toUpperCase() + plan.slice(1)} Plan`;

  const setFilter = (plan: Plan) => {
    setExpanded(false);
    onChange(plan);
  };

  return (
    <Container>
      <ActivePlanButton onClick={toggleMenu} data-testid="roi-plan-filter">
        <BodyP color={brand4} type="heading4">
          {formatOption(activePlan)}
        </BodyP>
        <Chevron expand={expanded} />
      </ActivePlanButton>
      <PlanDropdown
        expand={expanded}
        ref={filterContainer}
        maxHeight={scrollHeight}
        data-testid="roi-plan-dropdown-container"
      >
        {plans.map((plan) => (
          <Option
            key={plan}
            onClick={() => setFilter(plan)}
            isActive={activePlan === plan}
            data-testid={`plan-filter-${plan}`}
          >
            <BodyP type="heading4" color={grey700} textAlign="left">
              {formatOption(plan)}
            </BodyP>
          </Option>
        ))}
      </PlanDropdown>
    </Container>
  );
};

export default PostFilter;
